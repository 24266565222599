<template>
  <b-card header="Create Role">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent>
        <role-form :role="role" />

        <b-form-row>
          <back />

          <submit-button
            :handle-submit="handleSubmit"
            :submit="()=>addRole(roleProxy)"
            submit-text="Add"
            class="ml-auto"
          />
        </b-form-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import RoleForm from '@/common/components/Permissions/RoleForm.vue'
import Roles from '@/common/compositions/permissions/roles'

export default {
  name: 'CreateRole',
  components: { Back, SubmitButton, RoleForm },
  data() {
    return {
      role: {},
    }
  },
  computed: {
    roleProxy() {
      return {
        ...this.role,
        name: this.role.displayName,
      }
    },
  },
  setup() {
    const { addRole } = Roles()
    return { addRole }
  },
}
</script>
<style lang="scss">

</style>
